import { PERMISSION_IAM } from "../../iam/constants/permissions";

export interface AuthenticateRequest {
  email: string;
  password: string;
  source: PERMISSION_IAM;
}

export enum ErrorCode {
  TOKEN_MISMATCH = "TOKEN_MISMATCH",
}
