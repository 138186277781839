import React, { useEffect, useMemo } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CLink,
  CRow,
} from "@coreui/react";
import _get from "lodash/get";
import qs from "query-string";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getUserDataSelector, UserData } from "../../../../auth";
import { isAllowedAccess } from "../../../../auth/utils";
import { QuestButton } from "../../../../common/components/QuestButton";
import { DATATABLE_MAX_ROWS } from "../../../../common/constants/datatable";
import RefUserListFilter, {
  FilterData,
} from "../../../components/RefUserListFilter";
import RefUserTable from "../../../components/RefUserTable";
import { PERMISSION_IAM } from "../../../constants/permissions";
import useRefUserList from "../../../hooks/useRefUserList";
import { ListQueryParams } from "../../../types/ref-user";
import "./index.scss";

const RefClientList: React.FC = () => {
  const history = useHistory();
  const loggedInUser = useSelector(getUserDataSelector);
  const location = useLocation();
  const queryParams: ListQueryParams = useMemo(() => {
    return qs.parse(location.search, {
      parseNumbers: true,
      arrayFormat: "comma",
    });
  }, [location.search]);
  const { refUsers, setQueryParams } = useRefUserList({
    queryParams,
  });
  const filterData = useMemo(
    () => ({
      keyword: queryParams.keyword || "",
      client: queryParams.client
        ? {
            value: queryParams.client[0],
            label: queryParams.client[1],
          }
        : null,
    }),
    [queryParams]
  );

  const onFilterChangeHandler = (filterData: FilterData) => {
    setQueryParams({
      keyword: filterData.keyword || undefined,
      client: filterData.client
        ? [filterData.client?.value, filterData.client?.label]
        : undefined,
      page: undefined,
    });
  };

  const onClearFilersHandler = () => {
    history.push("?");
  };

  useEffect(() => {
    if (
      !isAllowedAccess(loggedInUser as UserData, [
        PERMISSION_IAM["IAM.USER.MANAGED.READ"],
      ])
    ) {
      return history.replace(`/`);
    }
  }, [loggedInUser, history]);

  return (
    <>
      <CRow className="quest-page-header-section">
        <CCol xs={12} className="align-items-center d-flex">
          <h2 className="quest-header-text">Referrer Salespersons</h2>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="12">
          <CCard className="quest-card">
            <CCardHeader className="d-flex justify-content-end">
              <CLink to="/iam/ref-users/new/admin">
                <QuestButton data-testid="btn-add-new-client">
                  Add a new referrer salesperson
                </QuestButton>
              </CLink>
            </CCardHeader>
            <CCardBody className="quest-datatable ref-user-list">
              <h4 className="f-bold f-quest-navy">
                Referrer salesperson filters:
              </h4>
              <RefUserListFilter
                filterData={filterData}
                onFilterChange={onFilterChangeHandler}
                onClearFilers={onClearFilersHandler}
                className="mb-2"
              />
              <RefUserTable
                data={refUsers.data}
                count={refUsers.count}
                loading={refUsers.loading}
                page={_get(queryParams, "page", 1)}
                perPage={_get(queryParams, "limit", DATATABLE_MAX_ROWS)}
                onParameterChange={setQueryParams}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default RefClientList;
