import React, { useEffect, useState } from "react";
import {
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CSelect,
  CSpinner,
} from "@coreui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import { AxiosError } from "axios";
import cs from "classnames";
import _pick from "lodash/pick";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { PopoverWithCustomChild } from "../../../../common/components/Popoverinfo/index";
import { getErrorClass } from "../../../../common/utils/validation";
import { processErrorMessage } from "../../../../error-handler/utils";
import { setNotification } from "../../../../notification/actions/creators";
import { useNotificationDispatch } from "../../../../notification/dispatchers";
import { updateApplication } from "../../../actions/creators/applicationForm";
import {
  lenders,
  statusLenderFormDefaults,
} from "../../../constants/applicationAdmin";
import {
  APPLICATION_STATUSES,
  APPLICATION_STATUS_LABELS,
} from "../../../constants/applicationStatuses";
import { useApplicationFormDispatch } from "../../../dispatchers";
import {
  getApplicationSelector,
  getIsApplicationStatusLockedSelector,
} from "../../../selectors/applicationForm";
import ApplicationService from "../../../services/ApplicationService";
import { StatusLenderForm } from "../../../types/ApplicationAdmin";
import { adminSchema } from "../../../validations/applicationAdmin";

type StatusAndLenderProps = {
  className?: string;
};
const StatusAndLender: React.FC<StatusAndLenderProps> = (
  props: StatusAndLenderProps
) => {
  const application = useSelector(getApplicationSelector);
  const isApplicationLocked = useSelector(getIsApplicationStatusLockedSelector);
  const dispatch = useApplicationFormDispatch();
  const notifDispatch = useNotificationDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { watch, reset, errors, control, handleSubmit } = useForm<
    StatusLenderForm
  >({
    resolver: joiResolver(adminSchema),
  });
  const formValues = watch();

  useEffect(() => {
    reset(
      _pick(application, [
        "status",
        "lenderName",
        "lenderRef",
      ]) as StatusLenderForm
    );
  }, [application, reset]);

  const onSave = async (data: StatusLenderForm) => {
    setIsLoading(true);
    try {
      const updatedApplication = await ApplicationService.administrate(
        application.id,
        formValues
      );
      notifDispatch(
        setNotification({
          body: "Status and Lender details has been saved",
          className: "qst-notif-success",
        })
      );
      dispatch(updateApplication(updatedApplication.data));
    } catch (error) {
      const message = processErrorMessage(error as AxiosError);
      notifDispatch(
        setNotification({
          body: message,
          className: "qst-notif-danger",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={cs("quest-form", props.className)}
      data-testid="status-lender-section"
    >
      <CContainer>
        <CRow>
          <CCol sm="12" md="4">
            <CFormGroup>
              <CLabel>Status</CLabel>
              <Controller
                name="status"
                control={control}
                defaultValue={statusLenderFormDefaults.status}
                render={({ value, onChange }) => {
                  const statusField = (
                    <div data-testid="status-cont">
                      <CSelect
                        value={value ?? ""}
                        onChange={onChange}
                        data-testid="status"
                        disabled={
                          isApplicationLocked || !application.submittedAt
                        }
                      >
                        <option value="" data-testid="status-select">
                          Select
                        </option>
                        {Object.keys(APPLICATION_STATUSES).map((status) => (
                          <option
                            key={status}
                            value={status}
                            data-testid={`status-${status}`}
                          >
                            {APPLICATION_STATUS_LABELS[status]}
                          </option>
                        ))}
                      </CSelect>
                    </div>
                  );

                  return (
                    <>
                      {application.submittedAt ? (
                        statusField
                      ) : (
                        <PopoverWithCustomChild
                          content={
                            <div>
                              Please submit the application <br />
                              first to enable status changes
                            </div>
                          }
                          positions={["top"]}
                        >
                          {statusField}
                        </PopoverWithCustomChild>
                      )}
                      {Boolean(errors.status) && (
                        <div>{errors?.status?.message}</div>
                      )}
                    </>
                  );
                }}
              />
            </CFormGroup>
          </CCol>
          <CCol
            sm="12"
            md="4"
            className={`form-group ${getErrorClass(errors.lenderName)}`}
          >
            <CFormGroup className="margin-bottom-zero">
              <CLabel>Lender Name</CLabel>
              <Controller
                name="lenderName"
                control={control}
                defaultValue={statusLenderFormDefaults.lenderName}
                render={({ value, onChange }) => {
                  return (
                    <CSelect
                      value={value ?? ""}
                      onChange={onChange}
                      data-testid="lender"
                      disabled={isApplicationLocked}
                    >
                      <option value="" data-testid="lender-select">
                        Select
                      </option>
                      {lenders.map((lender) => (
                        <option
                          key={lender}
                          value={lender}
                          data-testid={`lender-${lender}`}
                        >
                          {lender}
                        </option>
                      ))}
                    </CSelect>
                  );
                }}
              />
            </CFormGroup>

            {errors.lenderName && (
              <span className="validation-error" data-testid="lenderName-error">
                {"Lender name is required for settled deals"}
              </span>
            )}
          </CCol>
          <CCol sm="12" md="4">
            <CFormGroup>
              <CLabel>
                Lender Id <span className="font-weight-normal">(optional)</span>
              </CLabel>
              <Controller
                name="lenderRef"
                control={control}
                defaultValue={statusLenderFormDefaults.lenderRef}
                render={({ value, onChange }) => {
                  return (
                    <CInput
                      value={value ?? ""}
                      onChange={onChange}
                      maxLength={100}
                      data-testid="lender-ref"
                      disabled={isApplicationLocked}
                    />
                  );
                }}
              />
            </CFormGroup>
          </CCol>
          <CCol sm="12">
            <CButton
              className="quest-button primary mt-3"
              type="button"
              onClick={handleSubmit(onSave)}
              data-testid="save-status-lender"
              disabled={!application.id || isApplicationLocked}
            >
              {isLoading && (
                <CSpinner
                  size="sm"
                  className="my-1 mx-2"
                  data-testid="status-lender-spinner"
                />
              )}
              Save
            </CButton>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default StatusAndLender;
