import joi from "joi";
import { lenders } from "../constants/applicationAdmin";

export const adminSchema = joi.object({
  status: joi.string().messages({
    "string.empty": "Status is required",
  }),
  lenderName: joi
    .string()
    .valid(...Object.values(lenders))
    .optional()
    .when("status", {
      is: joi.valid("SETTLED", "IN_SETTLEMENT_WAITING_FOR_PAYMENT"),
      then: joi.required().messages({
        "any.required": "Lender name is required for settled deals",
      }),
      otherwise: joi.optional().allow("", null),
    }),
  lenderRef: joi.string().max(255).optional().allow("", null),
});
